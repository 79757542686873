const roadmapList = [
  [
    'Token Launch',
    'Verified Contract',
    'CoinGecko/ <br /> Coinmarketcap <br /> Listings',
    'Get $WWW Trending <br /> on Twitter ',
  ],
  [
    'Community <br /> Partnerships',
    'Engaged Telegram <br /> community',
    '1,000 Holders',
  ],
  [
    '20,000+ Twitter <br /> Followers',
    '5,000+holders',
    'Tier1 CEX Listings ',
    'Meme Takeover',
  ],
];

export default roadmapList;
