const elemMobile1 = (
  <svg
    width='6'
    height='28'
    viewBox='0 0 6 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_1612_282)'>
      <path
        d='M3.82411 0.80592H87.1758V4.93374C87.1758 6.44817 87.5692 7.93664 88.3173 9.25338L88.9375 10.3449C90.2253 12.6115 90.2253 15.3885 88.9375 17.655L88.3173 18.7466C87.5692 20.0633 87.1758 21.5518 87.1758 23.0662V27.194H3.82411V23.0662C3.82411 21.5518 3.43078 20.0633 2.68263 18.7466L2.06246 17.655C0.774634 15.3885 0.774635 12.6115 2.06246 10.3449L2.68263 9.25338C3.43078 7.93664 3.82411 6.44817 3.82411 4.93374V0.80592Z'
        fill='#FEBE63'
      />
      <path
        d='M3.82411 0.80592H87.1758V4.93374C87.1758 6.44817 87.5692 7.93664 88.3173 9.25338L88.9375 10.3449C90.2253 12.6115 90.2253 15.3885 88.9375 17.655L88.3173 18.7466C87.5692 20.0633 87.1758 21.5518 87.1758 23.0662V27.194H3.82411V23.0662C3.82411 21.5518 3.43078 20.0633 2.68263 18.7466L2.06246 17.655C0.774634 15.3885 0.774635 12.6115 2.06246 10.3449L2.68263 9.25338C3.43078 7.93664 3.82411 6.44817 3.82411 4.93374V0.80592Z'
        fill='url(#paint0_linear_1612_282)'
        fill-opacity='0.27'
      />
      <path
        d='M3.82411 0.80592H87.1758V4.93374C87.1758 6.44817 87.5692 7.93664 88.3173 9.25338L88.9375 10.3449C90.2253 12.6115 90.2253 15.3885 88.9375 17.655L88.3173 18.7466C87.5692 20.0633 87.1758 21.5518 87.1758 23.0662V27.194H3.82411V23.0662C3.82411 21.5518 3.43078 20.0633 2.68263 18.7466L2.06246 17.655C0.774634 15.3885 0.774635 12.6115 2.06246 10.3449L2.68263 9.25338C3.43078 7.93664 3.82411 6.44817 3.82411 4.93374V0.80592Z'
        stroke='#333333'
        stroke-width='1.34524'
      />
      <path
        d='M4.94832 7.5V2.00893H86.0517V7.5V7.78187L86.1978 8.02293L88.0243 11.0367C89.1282 12.8581 89.1282 15.1419 88.0243 16.9633L86.1978 19.9771L86.0517 20.2181V20.5V25.9911H4.94832V20.5V20.2181L4.80223 19.9771L2.97568 16.9633C1.87185 15.1419 1.87185 12.8581 2.97568 11.0367L4.80223 8.02293L4.94832 7.78187V7.5Z'
        fill='#FEBE63'
      />
      <path
        d='M4.94832 7.5V2.00893H86.0517V7.5V7.78187L86.1978 8.02293L88.0243 11.0367C89.1282 12.8581 89.1282 15.1419 88.0243 16.9633L86.1978 19.9771L86.0517 20.2181V20.5V25.9911H4.94832V20.5V20.2181L4.80223 19.9771L2.97568 16.9633C1.87185 15.1419 1.87185 12.8581 2.97568 11.0367L4.80223 8.02293L4.94832 7.78187V7.5Z'
        fill='url(#paint1_linear_1612_282)'
        fill-opacity='0.27'
      />
      <path
        d='M4.94832 7.5V2.00893H86.0517V7.5V7.78187L86.1978 8.02293L88.0243 11.0367C89.1282 12.8581 89.1282 15.1419 88.0243 16.9633L86.1978 19.9771L86.0517 20.2181V20.5V25.9911H4.94832V20.5V20.2181L4.80223 19.9771L2.97568 16.9633C1.87185 15.1419 1.87185 12.8581 2.97568 11.0367L4.80223 8.02293L4.94832 7.78187V7.5Z'
        stroke='#CC8843'
        stroke-width='2.01786'
      />
      <path
        d='M4.94832 7.5V2.00893H86.0517V7.5V7.78187L86.1978 8.02293L88.0243 11.0367C89.1282 12.8581 89.1282 15.1419 88.0243 16.9633L86.1978 19.9771L86.0517 20.2181V20.5V25.9911H4.94832V20.5V20.2181L4.80223 19.9771L2.97568 16.9633C1.87185 15.1419 1.87185 12.8581 2.97568 11.0367L4.80223 8.02293L4.94832 7.78187V7.5Z'
        stroke='url(#paint2_linear_1612_282)'
        stroke-opacity='0.27'
        stroke-width='2.01786'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_1612_282'
        x1='45.5'
        y1='27.9115'
        x2='45.5'
        y2='0.133301'
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop offset='0.458019' />
        <stop offset='0.463023' stop-opacity='0' />
        <stop offset='1' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1612_282'
        x1='45.5'
        y1='27.042'
        x2='45.5'
        y2='1'
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop offset='0.458019' />
        <stop offset='0.463023' stop-opacity='0' />
        <stop offset='1' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1612_282'
        x1='45.5'
        y1='27.042'
        x2='45.5'
        y2='1'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-opacity='0.87' />
        <stop offset='0.457919' stop-opacity='0' />
        <stop offset='0.458019' />
        <stop offset='0.703125' stop-opacity='0' />
        <stop offset='1' stop-opacity='0' />
      </linearGradient>
      <clipPath id='clip0_1612_282'>
        <rect width='6' height='28' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default elemMobile1;
