const elem2 = (
  <svg
    width='8'
    height='42'
    viewBox='0 0 8 42'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_1609_255-2)'>
      <path
        d='M-126.479 0.339123H2.4789V8.20027C2.4789 9.7147 2.87222 11.2032 3.62038 12.5199L6.36176 17.3448C7.64958 19.6113 7.64958 22.3884 6.36176 24.6549L3.62038 29.4798C2.87222 30.7965 2.4789 32.285 2.4789 33.7994V41.6606H-126.479V33.7994C-126.479 32.285 -126.872 30.7965 -127.62 29.4798L-130.362 24.6549C-131.65 22.3884 -131.65 19.6113 -130.362 17.3448L-127.62 12.5199C-126.872 11.2032 -126.479 9.71471 -126.479 8.20027V0.339123Z'
        fill='#FEBE63'
      />
      <path
        d='M-126.479 0.339123H2.4789V8.20027C2.4789 9.7147 2.87222 11.2032 3.62038 12.5199L6.36176 17.3448C7.64958 19.6113 7.64958 22.3884 6.36176 24.6549L3.62038 29.4798C2.87222 30.7965 2.4789 32.285 2.4789 33.7994V41.6606H-126.479V33.7994C-126.479 32.285 -126.872 30.7965 -127.62 29.4798L-130.362 24.6549C-131.65 22.3884 -131.65 19.6113 -130.362 17.3448L-127.62 12.5199C-126.872 11.2032 -126.479 9.71471 -126.479 8.20027V0.339123Z'
        fill='url(#paint0_linear_1609_255-2)'
        fill-opacity='0.27'
      />
      <path
        d='M-126.479 0.339123H2.4789V8.20027C2.4789 9.7147 2.87222 11.2032 3.62038 12.5199L6.36176 17.3448C7.64958 19.6113 7.64958 22.3884 6.36176 24.6549L3.62038 29.4798C2.87222 30.7965 2.4789 32.285 2.4789 33.7994V41.6606H-126.479V33.7994C-126.479 32.285 -126.872 30.7965 -127.62 29.4798L-130.362 24.6549C-131.65 22.3884 -131.65 19.6113 -130.362 17.3448L-127.62 12.5199C-126.872 11.2032 -126.479 9.71471 -126.479 8.20027V0.339123Z'
        stroke='#333333'
        stroke-width='1.34524'
      />
      <path
        d='M-124.93 11V2.00893H0.930466V11V11.2819L1.07657 11.5229L5.02432 18.0367C6.12816 19.8581 6.12816 22.1419 5.02432 23.9633L1.07657 30.4771L0.930466 30.7181V31V39.9911H-124.93V31V30.7181L-125.077 30.4771L-129.024 23.9633C-130.128 22.1419 -130.128 19.8581 -129.024 18.0367L-125.077 11.5229L-124.93 11.2819V11Z'
        fill='#FEBE63'
      />
      <path
        d='M-124.93 11V2.00893H0.930466V11V11.2819L1.07657 11.5229L5.02432 18.0367C6.12816 19.8581 6.12816 22.1419 5.02432 23.9633L1.07657 30.4771L0.930466 30.7181V31V39.9911H-124.93V31V30.7181L-125.077 30.4771L-129.024 23.9633C-130.128 22.1419 -130.128 19.8581 -129.024 18.0367L-125.077 11.5229L-124.93 11.2819V11Z'
        fill='url(#paint1_linear_1609_255-2)'
        fill-opacity='0.27'
      />
      <path
        d='M-124.93 11V2.00893H0.930466V11V11.2819L1.07657 11.5229L5.02432 18.0367C6.12816 19.8581 6.12816 22.1419 5.02432 23.9633L1.07657 30.4771L0.930466 30.7181V31V39.9911H-124.93V31V30.7181L-125.077 30.4771L-129.024 23.9633C-130.128 22.1419 -130.128 19.8581 -129.024 18.0367L-125.077 11.5229L-124.93 11.2819V11Z'
        stroke='#CC8843'
        stroke-width='2.01786'
      />
      <path
        d='M-124.93 11V2.00893H0.930466V11V11.2819L1.07657 11.5229L5.02432 18.0367C6.12816 19.8581 6.12816 22.1419 5.02432 23.9633L1.07657 30.4771L0.930466 30.7181V31V39.9911H-124.93V31V30.7181L-125.077 30.4771L-129.024 23.9633C-130.128 22.1419 -130.128 19.8581 -129.024 18.0367L-125.077 11.5229L-124.93 11.2819V11Z'
        stroke='url(#paint2_linear_1609_255-2)'
        stroke-opacity='0.27'
        stroke-width='2.01786'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_1609_255-2'
        x1='-62'
        y1='42.4021'
        x2='-62'
        y2='-0.333496'
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop offset='0.458019' />
        <stop offset='0.463023' stop-opacity='0' />
        <stop offset='1' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1609_255-2'
        x1='-62'
        y1='41.0647'
        x2='-62'
        y2='1'
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop offset='0.458019' />
        <stop offset='0.463023' stop-opacity='0' />
        <stop offset='1' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1609_255-2'
        x1='-62'
        y1='41.0647'
        x2='-62'
        y2='1'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-opacity='0.87' />
        <stop offset='0.457919' stop-opacity='0' />
        <stop offset='0.458019' />
        <stop offset='0.703125' stop-opacity='0' />
        <stop offset='1' stop-opacity='0' />
      </linearGradient>
      <clipPath id='clip0_1609_255-2'>
        <rect width='8' height='42' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default elem2;
